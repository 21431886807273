<template></template>

<script>
export default {
  mounted() {
    localStorage.setItem('jwtToken', this.$route.params.token)
    localStorage.setItem('jwtContract', '1')

    this.$router.replace({ name: 'T.Index.Page' })
  },
}
</script>